import './supportedbrowsers.css';
import { bindable } from 'aurelia-templating';
import {
  browserIsMobileOrTablet,
  browserIsDesktopOrAndroid,
  browserIsDesktop,
  browserIsAndroid
} from '../../../utility';

export class SupportedBrowsers {
  @bindable() continueClicked: Function | undefined;

  isMobileOrTablet = browserIsMobileOrTablet();
  isDesktopOrAndroid = browserIsDesktopOrAndroid();

  isDesktop = browserIsDesktop();
  isAndroid = browserIsAndroid();

  downloadBrowser() {
    if (this.isAndroid)
      window.location.href =
        'https://play.google.com/store/apps/details?id=com.android.chrome';
    if (this.isDesktop) window.location.href = 'https://www.google.com/chrome/';
  }
}
