import './unsupportedbrowser.css';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';

@autoinject()
export class UnsupportedBrowser {
  constructor(private router: Router) {}

  browserWarningIgnored() {
    this.router.navigateToRoute('dashboard');
  }
}
